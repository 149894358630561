<template>
	<div >
		<div class="ft20 cl-main ftw600">预约列表</div>
		<div class="pd30 bg-w mt20">
			<div>
				<router-link to="/project/addyyorder">
				<a-button type="primary" icon="plus">添加预约</a-button>
				</router-link>
			</div>
			<div class="form-search-box mt10">
				<a-form layout="inline">
					<a-form-item label="关键字">
						<a-input v-model="search.keyword" placeholder="请输入关键字"></a-input>
					</a-form-item>
					<a-form-item label="状态">
						<a-select style="width: 120px" placeholder="全部状态" v-model="search.status">
							<a-select-option :value="0">全部状态</a-select-option>
							<a-select-option :value="999">待到店</a-select-option>
							<a-select-option :value="1">已到店</a-select-option>
							<a-select-option :value="4">已取消</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="日期">
						  <a-date-picker  valueFormat="YYYY-MM-DD" v-model="search.day"/>
					</a-form-item>
				
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
					</a-form-item>
				</a-form>
			</div>

			<div class="">
				<div class="wxb-table-gray">
					<a-table rowKey="order_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">
						
						<div class="flex alcenter center" slot="project" slot-scope="project,record">
							{{project.project_name}}<span v-if="project.sku_id > 0">({{project.sku_name}})</span>
						</div>
						
						<div class="flex alcenter center" slot="contact" slot-scope="contact,record">
							{{contact.name}},{{contact.mobile}}
						</div>
						<div class="flex alcenter center" slot="car" slot-scope="car,record">
							{{car.car_name}},{{car.car_num}}
						</div>
						<div class="flex alcenter center" slot="price" slot-scope="price,record">
							¥{{price}}
						</div>
						<div class="flex alcenter center" slot="time" slot-scope="time,record">
							{{time.day}},{{time.yuyue_time}}
						</div>
						<div class="text-center" slot="mendian" slot-scope="mendian,record">
							{{mendian ? mendian.name : '-'}}
						</div>
						<div class="text-center" slot="status" slot-scope="status,record">
							
							<a-tag v-if="status == 0" color="orange">
							    待到店
							  </a-tag>
							  <a-tag v-if="status == 1"  color="green">
								已到店
							  </a-tag>
							  <a-tag v-if="status == 4" >
							  	已取消
							  </a-tag>
						</div>
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item v-if="record.status == 0">
											<a @click="changeStatusAct(record.order_id,1)" class="menu-act" href="javascript:;">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">确认到店</span>
											</a>
										</a-menu-item>
										<a-menu-item v-if="record.status == 0">
											<a @click="changeStatusAct(record.order_id,4)" class="menu-act" href="javascript:;">
												<i class="iconfont ft14 iconedit"></i>
												<span class="ml10">取消预约</span>
											</a>
										</a-menu-item>
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="delAct(record)">
												<i class="iconfont ft14 icondelete"></i>
												<span class="ml10">删除</span>
											</a>
										</a-menu-item>

									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins: [listMixin],
		data(){
			return{
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search:{
					keyword:'',
					status:0,
					day:'',
				},
				columns: [
					
					{title: '编号',dataIndex: 'order_id',align: 'center'},
					{title: '项目类型',key: 'project',align: 'center',width:300,scopedSlots: {customRender: 'project'}},
					{title: '联系人',key: 'contact',align: 'center',scopedSlots: {customRender: 'contact'}},
					{title: '车牌',key: 'car',align: 'center',scopedSlots: {customRender: 'car'}},
					{title: '门市价',dataIndex: 'price',align: 'center',scopedSlots: {customRender: 'price'}},
					{title: '预约时间',key: 'time',align: 'center',scopedSlots: {customRender: 'time'}},
					{title: '所属门店',dataIndex: 'mendian',align: 'center',scopedSlots: {customRender: 'mendian'}},
					{title: '状态',dataIndex: 'status',align: 'center',scopedSlots: {customRender: 'status'}},
					{title: '创建时间',dataIndex: 'add_time_format',align: 'center'},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getYuyueOrderList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					keyword:this.search.keyword,
					day:this.search.day,
					status:this.search.status
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.datas;
					
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			changeStatusAct(orderId,status){
				this.$http.api('admin/changeYuyueOrder',{
					orderId:orderId,
					status:status,
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.getLists();
					});
				}).catch(res=>{
					
				})
			},
			delAct(record){
				this.$confirm({
					title:'确认删除这个预约吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/delYuyueOrder',{
								orderId:record.order_id,
							}).then(res=>{
								this.$message.success('删除成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	
</style>
